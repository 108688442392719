* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a:-webkit-any-link {
    color: unset;
}

.section {
    width: 80%;
    display: flex;
    justify-content: center;
    margin: 24px auto;
}

.button-primary {
    color: white;
    background-color: #f4a005;
    padding: 12px;
    border-radius: 6px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    margin: 16px 0 0;
}

.color-first {
    background-color: #f4a005;
}

.color-second {
    background-color: #f2979a;
}

.color-third {
    background-color: #aac8ca;
}

