.recrutiment-wrapper {
    padding: 0 40px;
    margin: 40px 0;
    min-height: calc(100vh - 212px);

    .title {
        text-align: center;
    }

    .download-document-wrapper {
        margin: 40px 0;
        .download-document-title {
            background-color: #d2ebf3;
            border-radius: 22px;
            text-align: center;
            padding: 16px;
        }

        .text {
            color: #1f546c;
            font-weight: 700;
            font-size: 24px;
        }

        .item {
            @media (max-width: 450px) {
                width: 70%;
                text-align: center;
            }

            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 30px 0;
            cursor: pointer;
        }

        .icon {
            border: 1px solid #f4a005;
            height: 50px;
            width: 50px;
            background-color: #fce8e1;
            padding: 8px;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            margin-right: 16px;
        }

        .contact-title {
            color: #1f546c;
            font-size: 24px;
            font-weight: 700;
            margin: 0;
        }
    }

    .download-items-wrapper {
        display: flex;
        justify-content: space-around;
        margin: 24px 0;
        text-decoration: none;
    }

    .recruitment-info-wrapper {
        background-color: #f2979a;
        border: 5px solid orange;
        border-radius: 35px;
        padding: 24px;
        width: 85%;
        color: #fff;
        margin: 48px auto;

        @media (max-width: 850px) {
            margin-bottom: 80px;
        }

        .title {
            text-align: center;
            font-size: 32px;
            font-weight: 700;
            margin-bottom: 16px;
        }

        .text {
            line-height: 24px;

        }
    }
}
