.contact-page-wrapper {
  margin-bottom: 90px;

  .contact-details-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 24px 0;
    position: relative;

    @media (max-width: 500px) {
      text-align: center;
    }
  }

  .map-info-wrapper {
    width: 100%;
  }

  .sun-wrapper {
    position: absolute;
    right: 10px;

    @media (max-width: 630px) {
      width: 100%;
      display: none;
    }

    img {
      height: 140px;
      z-index: -1;
    }
  }

  .title-wrapper {

    .indian {
      height: 200px;
      position: absolute;
      left: 25px;
      top: 120px;
      z-index: -1;
    }
  }

}
