.footer-wrapper {
  position: relative;
  margin-top: 54px;
  width: 100%;
  background-color: #ccdee0;
  color: #1f546c;
  display: flex;
  align-items: center;
  padding: 20px 40px;
  font-size: 12px;

  .cloud {
    background: url("../../assets/cloud_blue.png");
    width: 100%;
    height: 100%;
    background-size: 155px;
    top: -53px;
    left: 0;
    overflow: hidden;
    position: absolute;

  }

}
