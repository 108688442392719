.slider-wrapper {
  margin: 24px auto;
  padding: 25px;
  width: 90%;
  position: relative;

  .slider-title {
    text-align: center;
    margin-bottom: 24px;
  }

  .slider-arrow {
    position: absolute;
    cursor: pointer;
    z-index: 1;

    @media (min-width: 320px) {
      top: 32%;
    }

    @media (min-width: 1024px) {
      top: 42%;
    }
  }

  .slider-next-arrow {
    right: 0;
  }

  .slider-prev-arrow {
    left: 0;
  }

  .img-cloud-background {
    position: absolute;
    top: 4%;
    right: -5%;
    height: 150px;
    z-index: -1;
  }

  .img-indian-background {
    position: absolute;
    top: 50%;
    right: 2%;
    height: 320px;
    z-index: -1;
  }
}

.slick-track {
  @media (min-width: 800px) {
    height: 460px;
  }
}

