.navbar-wrapper {
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 35px 0;
    height: 80px;
    background: linear-gradient(0deg, rgba(252,232,225,1) 0%, rgba(245,174,176,1) 70%, rgba(242,151,154,1) 100%);
    border-bottom: 1px solid orange;
    position: sticky;
    width: 100%;
    top: 0;

    .navbar-logo {
        height: 70px;
        width: 75px;
    }

    .gray {
        color: #ccc;
    }
    a:hover {
        opacity: 1;
    }
    .fa-bars {
        display: none;
        color: #222;
        font-size: 2rem;
    }
    .navbar-nav {
        ul {
            display: flex;
            justify-content: space-between;
            list-style-type: none;
        }
        a {
            font-size: 1em;
            text-decoration: none;
            .active {
                color: tomato;
            }
        }
        a.active {
            color: #222;
        }
    }

    .logo {
        width: 185px;
    }

    @media only screen and (max-width: 800px) {
        padding: 0;
        .logo {
            padding-left: 15px;
            padding-top: 0 !important;
        }
    }
    @media only screen and (max-width: 600px) {
        height: auto;
        min-height: 50px;
        display: block;
        position: relative;
        .logo {
            width: 100%;
            display: block;
            padding-top: 20px;
            margin: 0;
            margin-left: -5px;
            a {
                padding: 20px 0;
            }
        }
        .fa-bars {
            display: inline-block;
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
        }
        ul.collapsed {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-wrap: wrap;

            overflow: hidden;
            max-height: 0;
            -moz-transition-duration: 0.4s;
            -webkit-transition-duration: 0.4s;
            -o-transition-duration: 0.4s;
            transition-duration: 0.4s;
            -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
            -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
            -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
            transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

            &.is-expanded {
                overflow: hidden;
                max-height: 500px; /* approximate max height */
                -moz-transition-duration: 0.4s;
                -webkit-transition-duration: 0.4s;
                -o-transition-duration: 0.4s;
                transition-duration: 0.4s;
                -moz-transition-timing-function: ease-in;
                -webkit-transition-timing-function: ease-in;
                -o-transition-timing-function: ease-in;
                transition-timing-function: ease-in;
            }
            li {
                padding: 15px 10px;
                margin: 0px 0px;
                width: 100%;
            }
        }
    }

    .navbar-nav-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .navbar-nav-link {
        margin: 0 35px;
        color: #1f546c;
        height: 80px;
        display: flex;
        align-items: center;
    }

    .navbar-nav-caption {
        text-transform: UPPERCASE;
        font-weight: 700;
    }

    .navbar-contact {
        color: #1f546c;
        font-size: 12px;

        @media (max-width: 600px) {
            position: absolute;
            top: 15px;
            left: 31%;
        }

        .navbar-contact-item {
            cursor: pointer;
            width: 205px;

            a {
                text-decoration: none;
            }
        }
    }

    .navbar-contact-text {
        margin-left: 8px;
    }
}
