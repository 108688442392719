.recruitment-dialog-wrapper {
  background-color: #d2ebf3;
  position: absolute;
  overflow: hidden;
  inset: 40px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 40px;
  outline: none;
  padding: 20px;
  margin: auto;
  height: 80%;
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #20546c;
  text-transform: uppercase;

  .text-bold {
    font-size: 22px;
    font-weight: 700;
    line-height: 34px;
  }


  .image-dialog {
    @media (min-width: 300px) {
      max-height: 20%;
    }

    @media (min-width: 360px) {
      max-height: 30%;
    }

    @media (min-width: 800px) {
      max-height: 60%;
    }

    display: flex;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;

    img {
      object-fit: contain;
    }
  }

  .close-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }

}
