.widget-wrapper {
  @media (max-width: 884px) {
    margin: 24px 0;
  }

  @media (min-width: 540px) {
    height: 365px;
  }

  background-color: #f8c3c2;
  color: #1f546c;
  border-radius: 22px;
  padding: 24px;
  text-align: center;

  .title {
    font-size: 22px;
  }

  .divider {
    background-color: #f6bd4f;
    height: 3px;
    margin: 12px 0;
    border-radius: 5px;
  }

  .price {
    font-size: 34px;
    margin: 40px 0;
    font-weight: 700;
  }

  .price-small {
    font-size: 16px;
    font-weight: 300;
  }

  .widget-details {
    height: 190px;
    background-color: white;
    border-radius: 25px;
    padding: 12px;

    @media (min-width: 460px) {
      height: 150px;
    }
  }

  .detail-divider {
    background-color: #1f546c;
    height: 1px;
  }

  .caption-detail {
    font-size: 18px;
    margin: 10px 0;
  }
}
