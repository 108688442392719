.summary-contact-wrapper {
  width: 100%;

  .contact-item {
    @media (max-width: 884px) {
      width: 100%;
    }

    display: flex;
    flex-direction: row;
    margin: 30px 0;
    width: 35%;
  }

  .contact-item-contact {
    @media (min-width: 884px) {
      width: 40%
    }
  }

  .contact-icon {
    border: 1px solid #f4a005;
    height: 57px;
    width: 57px;
    background-color: #fce8e1;
    padding: 12px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
  }

  .contact-info-wrapper {
    margin-left: 14px;
    margin-right: 14px;
    color: #1f546c;
    width: 100%;
  }

  .contact-title {
    color: #1f546c;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
  }

  .contact-info {
    color: #f4a005;
    line-height: 28px;
    margin: 8px 0;
    text-decoration: none;
  }

  .contact-row {
    @media (min-width: 580px) {
      align-items: center;
    }

    @media (max-width: 884px) {
      flex-direction: column;
    }

    display: flex;
    justify-content: space-evenly;
  }
}
