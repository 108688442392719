.header-wrapper {
    height: 100%;
    width: 100%;
}

.header-banner {
    height: 100%;
    width: 100%;
}

.header-navbar {
    text-align: center;
    height: 40px
}

.banner {
    width: 100%;
    height: 100%;
}

.header-banner-gradient {
    z-index: -1;
    position: relative;
    top: -4px;
    height: 45px;
    background: linear-gradient(180deg, rgba(252,232,225,1) 0%, rgba(252,232,225,1) 19%, rgba(255,255,255,1) 46%);}
