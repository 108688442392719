@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;1,100;1,300;1,400&display=swap');

body {
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  /*background-color: #fce8e1;*/
  font-family: 'Roboto', sans-serif;
}

.dialog-active {
  overflow: hidden;
}

.dialog-active .navbar-wrapper {
  z-index: 0;
}

.dialog-active .slick-list {
  z-index: -1;
}
