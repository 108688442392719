.offer-wrapper {
  margin-bottom: 180px;
  padding: 0 40px;

  .offer-section {
    margin: 24px 0;
  }

  .page-caption {
    display: flex;
    justify-content: center;
  }

  .caption-wrapper {
    margin: 24px 0;
  }

  .offer-widgets {
    @media (min-width: 320px) {
      flex-direction: column;
      align-items: center;
    }

    @media (min-width: 884px) {
      flex-direction: row;
    }

    display: flex;
    justify-content: space-evenly;
    margin-bottom: 48px;
  }

  .section-included-in-price {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
  }

  .offer-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
  }

  .offer-icon {
    border: 1px solid #f4a005;
    height: 50px;
    width: 50px;
    background-color: #fce8e1;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
  }

  .items-container-row {
    margin-top: 24px;
    display: flex;
    justify-content: space-evenly;
  }

  .center-container {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .offer-caption {
    margin-top: 14px;
    font-size: 11px;
    max-width: 250px;
    text-align: center;
    color: #1f546c;
  }

  .offer-discount {
    background-color: #f8c3c2;
    border: 5px solid #f6bd4f;
    border-radius: 22px;
    padding: 24px;
    width: 85%;
    margin: 48px auto;
    text-align: center;
    color: #1f546c;

    .title {
      text-align: center;
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 16px;
    }

    .text {
      line-height: 24px;

    }
  }

  .offer-info-children-wrapper {
    margin: 24px 0 48px;
    background-color: #d2ebf3;
    border-radius: 22px;
    text-align: center;
    padding: 16px;
    .text {
      color: #1f546c;
      font-weight: 700;
      font-size: 24px;
    }
  }

  .additional-items-caption {
    margin: 48px 0 0;
    color: #1f546c;
    font-size: 12px;
  }

  .sidebar-image {


    .img-japan {
      position: absolute;
      width: 95px;
      top: 90px;
    }

    .img-indian {
      position: absolute;
      width: 100px;
      top: 31%;
      right: 5px;
      display: none;

      @media (min-width: 650px) {
        display: block;
      }

      @media (min-width: 884px) {
        top: 10%;
        width: 100px;
      }

      @media (min-width: 1000px) {
        top: 35%;
        width: 150px;
        right: -10px;
      }
    }

    .img-tree {
      position: absolute;
      width: 150px;
      top: 70%;
      left: -5px;
      z-index: -1;
    }

  }
}
