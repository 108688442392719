.title-caption-wrapper {
  .letter {
    font-size: 50px;
  }

  .first-letter {
    color: #f4a005;
  }

  .second-letter {
    color: #f2979a;
  }

  .third-letter {
    color: #aac8ca;
  }
}
