.slider-element-wrapper {
  display: flex;
  width: 90%;
  margin: 24px auto;
  z-index: 0;
  position: relative;
  max-width: 900px;

  .slider-image {
    max-height: 450px;
    width: 100%;
  }
}
