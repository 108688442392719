.invite-to-contact-wrapper {
  padding: 24px;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;

  .invite-container {
    @media (min-width: 700px) {
      height: 100%;
    }

    width: 80%;
    background-color: #61a1a5;
    margin: auto 0;
    border-radius: 22px;
    padding: 24px;
    color: white
  }

  .title {
    color: white;
    text-align: center;
    font-size: 38px;
    margin-bottom: 24px;
  }

  .caption {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }

  .contact-button {
    justify-content: center;
    display: flex;
    margin: 30px;
  }

  .link {
    background-color: #f4a005;
    padding: 10px 24px;
    border-radius: 22px;
    height: 40px;
    text-decoration: none;

    @media (max-width: 440px) {
      height: 50px;
      text-align: center;
    }
  }

}
